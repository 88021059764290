export function manageLastSectionBlack() {
  const sections = document.querySelectorAll(
    'section[data-color]:not([data-color="black"])',
  );

  const sectionsBlack = document.querySelectorAll(
    'section[data-color="black"]:not(.first)',
  );

  if (sections.length > 0) {
    const lastSection = sections[sections.length - 1] as HTMLElement;
    const lsatSectionBlack = sectionsBlack[
      sectionsBlack.length - 1
    ] as HTMLElement;

    lastSection.style.borderRadius = "30px"; // Add your desired border style here
    lastSection.style.marginBottom = "-30px";

    if (window.innerWidth > 1200 && window.innerWidth < 1920) {
      lastSection.style.borderRadius = "40px"; // Add your desired border style here
      lastSection.style.marginBottom = "-40px";
    }

    if (window.innerWidth > 1920) {
      lastSection.style.borderRadius = "60px"; // Add your desired border style here
      lastSection.style.marginBottom = "-60px";
    }

    if (lsatSectionBlack) {
      lsatSectionBlack.style.zIndex = String(1);
      lsatSectionBlack.removeAttribute("data-color");
      lsatSectionBlack.style.borderRadius = "0px";
    }
  }
}
