export function increaseZIndexForSections() {
  // Get all the section elements on the page
  const sections: HTMLCollectionOf<HTMLElement> =
    document.getElementsByTagName("section");

  // Loop through each section and set its z-index based on its index
  for (let i = 0; i < sections.length; i++) {
    sections[i].style.zIndex = (i + 1).toString();
  }
}
