document.addEventListener("DOMContentLoaded", function () {
  const textArea = document.querySelector("#formTextarea");

  if (textArea) {
    // Initial height setting
    const baseHeight = textArea.scrollHeight;
    textArea.style.height = `${baseHeight}px`;

    textArea.addEventListener("input", function () {
      const charCount = this.value.length;

      // Calculate additional height based on character count
      const additionalHeight = Math.floor(charCount / 50) * baseHeight;

      // Set the new height
      this.style.height = `${baseHeight + additionalHeight}px`;
      console.log(this.style.height);
    });
  }
});
