export default function SmoothScroll() {
  // Select all anchor links that contain a hash
  const buttons = document.querySelectorAll(
    'a[href*="#"]',
  ) as NodeListOf<HTMLLinkElement>;

  const singleCase = document.querySelector("body.single-cases");

  buttons.forEach((button) => {
    button.addEventListener("click", function (e) {
      e.preventDefault();

      // Only proceed if the link is for the same page
      const url = new URL(button.href);
      if (
        url.origin === window.location.origin &&
        url.pathname === window.location.pathname
      ) {
        const target = document.querySelector(url.hash);
        let topCorrectValue: number = 80;
        if (singleCase) {
          topCorrectValue = 200;
        }
        if (target) {
          window.scrollTo({
            behavior: "smooth",
            top:
              target.getBoundingClientRect().top -
              document.body.getBoundingClientRect().top +
              topCorrectValue,
          });
        }
      }
    });
  });
}

const values = document.getElementById("values");

function observerCallback(
  entries: IntersectionObserverEntry[],
  observer: IntersectionObserver,
) {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      values?.scrollIntoView({
        behavior: "smooth", // Changed from "instant" to "smooth"
      });
      observer.unobserve(entry.target); // Unobserve to prevent repeated triggers
    }
  });
}

if (values) {
  const observer = new IntersectionObserver(observerCallback, {
    root: null, // Viewport is the root
    rootMargin: "0px",
    threshold: 0.1, // Callback when 10% of the element is visible
  });

  observer.observe(values);
}
