document.addEventListener("DOMContentLoaded", function () {
  const items = document.querySelectorAll(".cooperation-content-item");
  let lastActiveItem: HTMLElement | null = null;

  items.forEach((item) => {
    item.addEventListener("mouseover", function () {
      items.forEach((item) => {
        item.classList.remove("cooperation-content-item-open");
      });
      item.classList.add("cooperation-content-item-open");
      // @ts-ignore
      lastActiveItem = item;
    });

    item.addEventListener("mouseleave", function () {
      if (lastActiveItem === item) {
        return;
      }
      item.classList.remove("cooperation-content-item-open");
    });
  });
});
