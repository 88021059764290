const MULTIPLIER = 0.7;

const careerOffice = document.querySelector(".career_offices");
if (careerOffice) {
  const sectionCareerOffice = careerOffice.parentNode;

  (function () {
    if (sectionCareerOffice) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      window.addEventListener("scroll", (event) => {
        // Check if container is in view
        if (
          window.scrollY > sectionCareerOffice.offsetTop &&
          window.scrollY <
            sectionCareerOffice.offsetTop +
              sectionCareerOffice.offsetHeight +
              window.innerHeight
        )
          [...document.querySelectorAll(".image")].forEach((elem) => {
            const seed = Number(elem.dataset.seed);
            const scrollAmount =
              -1 *
              (Math.max(0, window.scrollY - sectionCareerOffice.offsetTop) *
                seed *
                MULTIPLIER);
            elem.style.transform = `translate(0,${scrollAmount}px)`;
          });
      });
    }
  })();
}
