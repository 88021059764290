// Callback function to be executed when the "contact" element intersects with the viewport
const callback = (entries, observer) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting && entry.target.id === "contact") {
      animateValues();
      observer.unobserve(entry.target);
    } else if (entry.isIntersecting && entry.target.id === "num_info") {
      animateValues();
      observer.unobserve(entry.target);
    }
  });
};

// Create a new Intersection Observer
const observer = new IntersectionObserver(callback);

// Get the "contact" element
const contactElement = document.querySelector("#contact");
const numInfoElement = document.querySelector("#num_info");
const numInfoElement2 = document.querySelector("#career_num_info");

if (contactElement) {
  observer.observe(contactElement);
}

if (numInfoElement) {
  observer.observe(numInfoElement);
}

if (numInfoElement2) {
  observer.observe(numInfoElement2);
}

// Function to execute when the "contact" element is in the viewport
function animateValues() {
  function animateValue(obj, start, end, duration) {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      // @ts-ignore
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      obj.innerHTML = Math.floor(progress * (end - start) + start);
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  }

  const numTitle1 = document.getElementById("numYears");
  animateValue(numTitle1, 0, 7, 1000);

  const numTitle2 = document.getElementById("numClients");
  animateValue(numTitle2, 0, 100, 1000);

  const numTitle3 = document.getElementById("numScore");
  animateValue(numTitle3, 0, 90, 1000);

  const numTitle4 = document.getElementById("numRate");
  animateValue(numTitle4, 0, 3, 1000);

  const numTitle5 = document.getElementById("numHeadcount");
  animateValue(numTitle5, 0, 170, 1000);

  const numTitle6 = document.getElementById("numNet");
  animateValue(numTitle6, 0, 80.7, 1000);
}
