const button = document.getElementById("mobileSidebarNav") as HTMLDivElement;
if (button) {
  button.addEventListener("click", function (event) {
    const functionHandle = sidebarMenuButtonHandleClick.bind(this);
    functionHandle(event);
  });

  const anchors = button?.parentNode?.querySelectorAll(".postNavigationItem");
  anchors?.forEach((anchor) => {
    anchor.addEventListener("click", function () {
      const active = button.parentNode?.querySelector(
        ".postNavigationItem.active",
      );
      active?.classList.remove("active");
      this.classList.add("active");
      const text = button.querySelector(".text") as HTMLDivElement;
      text.textContent = this.textContent;
      button.parentNode?.classList.remove("active");
      document.querySelector("body")?.classList.remove("disable-scroll");
    });
  });
}

function sidebarMenuButtonHandleClick(event: Event) {
  event.preventDefault();
  document.querySelector("body")?.classList.toggle("disable-scroll");
  this.parentNode.classList.toggle("active");
}
