const sidebar = document.querySelector(".career-sidebar") as HTMLElement;

const sidebarPosition = sidebar?.getBoundingClientRect();
const windowOffsetOnPageLoad = window.scrollY;
const windowOffsetCorrection = 150;
const sidebarOffset =
  sidebarPosition?.top + windowOffsetOnPageLoad - windowOffsetCorrection;

const sidebarArrow = sidebar?.querySelector(
  ".career-sidebar-content-list-nav",
) as HTMLDivElement;
const sidebarItems = sidebar?.querySelectorAll(
  ".career-sidebar-content-list-item",
) as NodeListOf<HTMLElement>;
const refLinks = sidebar?.querySelectorAll(
  ".career-sidebar-content-list-item a",
) as NodeListOf<HTMLLinkElement>;

//offset correction for LI element(li element has 15px top padding)
const arrowOffsetCorrection = 15;

let isClicked = false;

export function CareerSidebar() {
  window.addEventListener("scroll", moveSidebar);
  arrowNavigation();
  trackArrowPosition();
}

function moveSidebar() {
  if (sidebar) {
    // fix sidebar
    if (sidebarOffset <= window.scrollY) {
      sidebar.classList.add("fixed");
    } else {
      sidebar.classList.remove("fixed");
    }
  }
}

function arrowNavigation() {
  // Set active item element
  sidebarItems?.forEach(function (item) {
    item.addEventListener("click", function () {
      isClicked = true;
      // first remove from all
      sidebarItems?.forEach(function (item) {
        item.classList.remove("active");
      });
      // then set active
      item.classList.add("active");
      setTimeout(function () {
        isClicked = false;
      }, 300);
    });

    // move arrow on hover
    item.addEventListener("mouseover", function () {
      sidebarArrow.style.top = `${this.offsetTop + arrowOffsetCorrection}px`;
    });

    // move arrow to active item
    item.addEventListener("mouseleave", function () {
      const activeItem = sidebar?.querySelector(
        ".career-sidebar-content-list-item.active",
      ) as HTMLElement;

      sidebarArrow.style.top = `${
        activeItem.offsetTop + arrowOffsetCorrection
      }px`;
    });
  });
}

// get all id elements on page content

function trackArrowPosition() {
  document.addEventListener("scroll", trackArrowPositionOnScroll);
}

function trackArrowPositionOnScroll() {
  refLinks?.forEach(function (item) {
    // @ts-ignore
    const refElement = document.querySelector(item.getAttribute("href"));
    // @ts-ignore
    const refElementPosition = refElement.getBoundingClientRect();
    const parentElement = item.parentNode as HTMLElement;
    if (
      refElementPosition.top - windowOffsetCorrection <= 0 &&
      refElementPosition.top + refElementPosition.height >= 0 &&
      !isClicked
    ) {
      parentElement?.classList.add("active");
      sidebarArrow.style.top = `${
        parentElement.offsetTop + arrowOffsetCorrection
      }px`;
    } else {
      parentElement?.classList.remove("active");
    }
  });
}
