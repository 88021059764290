const buttons = document.querySelectorAll(
  ".filter-list-item-arrow",
) as NodeListOf<HTMLButtonElement>;

const closeBtn = document.querySelector(
  ".close-all-filter-list",
) as HTMLButtonElement;

const careerList = document.getElementById("career-list");
const careerHero = document.getElementById("career-hero");
careerList?.addEventListener("click", function (): void {
  closeOpenedSubmenus();
  header?.classList.remove("bg-black");
  careerHero?.classList.remove("bg-black");
  closeBtn?.remove("active");
});

closeBtn?.addEventListener("click", function (event: Event) {
  event.preventDefault();
  closeOpenedSubmenus();

  const header = document.querySelector("header") as HTMLElement | null;

  const section: HTMLElement | null = this.closest(
    "section",
  ) as HTMLElement | null;

  header?.classList.remove("bg-black");
  section?.classList.remove("bg-black");
  closeBtn?.classList.remove("active");

  const filterListMenu = document.querySelectorAll(".filter-list-submenu");
  if (filterListMenu) {
    Array.from(filterListMenu).forEach((child) => {
      const childElement = child.querySelector(".selected");
      const parentElement = child.parentElement;
      if (childElement) {
        parentElement.style.opacity = "1";
      } else {
        parentElement.style.opacity = "";
      }
    });
  }
});

const header = document.querySelector(".header");

function showFilterSubmenu(button: HTMLButtonElement) {
  const liElement = button.parentNode as HTMLLIElement;
  liElement?.addEventListener("click", function (event: Event) {
    event.preventDefault();
    if (!this.classList.contains("active")) {
      closeOpenedSubmenus();
    }
    this.classList.toggle("active");
    const subMenu = this.querySelector(
      ".filter-list-submenu",
    ) as HTMLUListElement;
    const section = this.closest("section");
    if (subMenu) {
      const coords = this.getBoundingClientRect();
      subMenu.style.paddingLeft = coords.left + "px";
      if (subMenu.style.maxHeight) {
        subMenu.style.maxHeight = "";
        if (window.innerWidth > 992) {
          header?.classList.remove("bg-black");
          section?.classList.remove("bg-black");
          closeBtn?.classList.remove("active");
        }
      } else {
        if (window.innerWidth > 992) {
          header?.classList.add("bg-black");
          section?.classList.add("bg-black");
          closeBtn?.classList.add("active");
          const heightCorrection = 130;
          subMenu.style.maxHeight =
            heightCorrection + subMenu.scrollHeight + "px";
          subMenu.style.left = -coords.left + "px";
          subMenu.style.top = 40 + "px";
        } else {
          subMenu.style.maxHeight = subMenu.scrollHeight + "px";
        }
      }
    }
  });
}

function closeOpenedSubmenus() {
  const items = document.querySelectorAll(
    ".filter-list-item.active",
  ) as NodeListOf<HTMLLIElement>;
  items.forEach(function (item) {
    item.classList.remove("active");
    const subMenu = item.querySelector(
      ".filter-list-submenu",
    ) as HTMLUListElement;
    if (subMenu) {
      if (subMenu.style.maxHeight) {
        subMenu.style.maxHeight = "";
      }
    }
  });
}

buttons?.forEach(showFilterSubmenu);

const filterItems = document.querySelectorAll(
  ".filter-list-submenu .filter-list-item",
) as NodeListOf<HTMLLIElement>;

function selectFilterItem(item: HTMLLIElement) {
  item.addEventListener("click", function (event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.classList.toggle("selected");
  });
}

filterItems?.forEach(selectFilterItem);

const applyBtn = document.getElementById("apply-filter");

if (applyBtn) {
  applyBtn.addEventListener("click", function (event: Event) {
    event.preventDefault();
    const filter = filterRecords.bind(this);
    filter();
  });
}
function filterRecords(this: any) {
  const selectedItems = getAllSelectedItems();
  if (selectedItems) {
    this.classList.add("loading");
    const contentSelector = this.getAttribute("data-content-id");
    const content = document.getElementById(
      contentSelector ?? "",
    ) as HTMLDivElement;
    const id = this.getAttribute("data-filters");
    if (id && content) {
      let filterString = "";
      selectedItems.forEach((item) => {
        filterString += `&taxonomy[]=${item}`;
      });
      // @ts-ignore
      content.parentNode?.classList.add("loading");
      fetchFilteredData(filterString, id).then((data) => {
        insertFoundResults(content, data);
        // @ts-ignore
        content.parentNode?.classList.remove("loading");
      });
    }
  }
}
if (window.innerWidth >= 1200) {
  const buttons = document.querySelectorAll(
    ".filter-list-submenu .filter-list-item",
  ) as NodeListOf<HTMLLIElement>;
  if (buttons) {
    buttons.forEach((button) => {
      button.addEventListener("click", function () {
        const filter = filterRecords.bind(applyBtn);
        filter();
      });
    });
  }
}
function getAllSelectedItems(): string[] {
  const selectedItems = document.querySelectorAll(
    ".filter-list-item.selected",
  ) as NodeListOf<HTMLLIElement>;
  const result: string[] = [];
  if (selectedItems) {
    selectedItems.forEach(function (item) {
      const value = item.getAttribute("data-slug");
      if (value !== null) {
        result.push(value);
      }
    });
  }
  return result;
}

async function fetchFilteredData(queryParams: string, endpoint: string) {
  const response = await fetch(
    `/wp-json/StartupSoft/v1/${endpoint}?page=1${queryParams}`,
    {
      method: "GET",
    },
  );
  return response.json();
}

// Search functionality
const searchField = document.querySelector("[data-search]") as HTMLInputElement;
searchField?.addEventListener("input", handleSearchInput);

const content = document.getElementById(
  searchField?.getAttribute("data-content-id") as string,
) as HTMLDivElement;

let timeOut;
function handleSearchInput(event: Event) {
  event.preventDefault();
  clearTimeout(timeOut);
  timeOut = setTimeout(function () {
    findPosts(event);
  }, 1000);
}

async function findPosts(event: Event) {
  // @ts-ignore
  const value = event.target.value;
  const endpoint = searchField?.getAttribute("data-search") as string;
  if (endpoint && content) {
    // @ts-ignore
    content.parentNode?.classList.add("loading");
    fetchFilteredData(`&s=${value}`, endpoint).then((data) => {
      insertFoundResults(content, data);
      // @ts-ignore
      content.parentNode?.classList.remove("loading");
    });
  }
}

function insertFoundResults(content: HTMLDivElement, data) {
  if (data) {
    content.innerHTML = data.posts;
    if (data.postNumber == 0) {
      content.classList.add("empty");
    } else {
      content.classList.remove("empty");
    }
  }
}
