function closeFilterModal(event: Event, id: string) {
  event.preventDefault();
  const modal = document.getElementById(id);
  if (modal) {
    modal.classList.remove("active");
  }
}

function openFilterModal(event: Event, id: string) {
  event.preventDefault();
  const modal = document.getElementById(id);
  if (modal) {
    modal.classList.add("active");
  }
}

window.closeFilterModal = closeFilterModal;
window.openFilterModal = openFilterModal;
