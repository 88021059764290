document.addEventListener("DOMContentLoaded", function () {
  const contentMain = document.querySelector(".postMainContent");
  const careerSingle = document.querySelector(".single-career");

  if (window.screen.width >= 1200) {
    if (careerSingle) {
      const sideNav = document.querySelector(".careerSideNav");
      const sideNavHeight = sideNav?.clientHeight;

      // @ts-ignore
      contentMain.style.marginTop = `-${sideNavHeight}px`;
    }

    if (contentMain) {
      const observerPostOptions = {
        root: null, // Use the viewport as the container
        rootMargin: "0px",
        threshold: 0.2, // Trigger when 10% of the element is visible
      };

      const sectionsWithId = contentMain.querySelectorAll("section[id]");
      const divWithId = contentMain.querySelectorAll("div[id]");

      const observerCallback = (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const activeId = entry.target.getAttribute("id");

            if (!activeId) return; // Ensure the target element has an ID

            const postNavigationItems = document.querySelectorAll(
              "div.postNavigationItem",
            );

            postNavigationItems.forEach((item) => {
              if (item.classList.contains(activeId)) {
                item.classList.add("active");
              } else {
                item.classList.remove("active");
              }
            });
          }
        });
      };

      const observer = new IntersectionObserver(
        observerCallback,
        observerPostOptions,
      );

      // Assuming elements to observe have the class 'postSection'
      document.querySelectorAll(".postSection").forEach((section) => {
        observer.observe(section);
      });

      sectionsWithId.forEach((section) => {
        observer.observe(section);
      });

      divWithId.forEach((section) => {
        observer.observe(section);
      });
    }
  }
});
