const cardEpisode = document.querySelector(".episode-card");
const imgCardEpisode = document.querySelector(".episode-card-box-right");

if (window.screen.width <= 1200) {
  if (cardEpisode) {
    const newCardHeight =
      // @ts-ignore
      imgCardEpisode.clientHeight + cardEpisode.clientHeight - 50;
    cardEpisode.setAttribute("style", `height:${newCardHeight}px`);
  }
}
