import { showPopup } from "./popupVideo";

// @ts-nocheck
export function modalButtonHandler() {
  const modalButtons = document.querySelectorAll(".slide-open");
  if (modalButtons.length > 0) {
    modalButtons.forEach((button) => {
      button.addEventListener("click", function (event) {
        event.preventDefault();
        // @ts-ignore
        const videoId = this.getAttribute("data-video-id");
        loadVideo(videoId);
        showPopup();
      });
    });
  }
}

export function videoHandler(): void {
  const mediaPlayers = document.querySelectorAll(".article-media-video");

  if (mediaPlayers.length > 0) {
    mediaPlayers.forEach((mediaPlayer) => {
      const videoId = mediaPlayer.getAttribute("data-video-id");
      if (videoId) {
        // @ts-ignore
        window.YT.ready(function () {
          // @ts-ignore
          window.player = new YT.Player("media-player", {
            height: "530px",
            width: "100%",
            videoId: videoId, // Replace with your YouTube video ID
            events: {
              onReady: onPlayerReady,
              onStateChange: onPlayerStateChange,
            },
          });
        });
      }
    });
  }
}

function loadVideo(id) {
  // Ensure YouTube API script is loaded before calling YT.Player
  // Also, ensure that the element IDs "feedback-player" and "media-player" exist in your HTML.
  // @ts-ignore
  if (typeof YT !== "undefined" && YT.Player) {
    // @ts-ignore
    window.player = new YT.Player("feedback-player", {
      height: "360",
      width: "640",
      videoId: id, // Replace with your YouTube video ID
      events: {
        onReady: onPlayerReady,
        onStateChange: onPlayerStateChange,
      },
    });
  } else {
    console.error("YouTube API not loaded.");
    console.log(id);
  }
}

function onPlayerReady(event) {
  // Player is ready
  console.log(event);
}

function onPlayerStateChange(event) {
  // Handle player state changes if needed
  console.log(event);
}
